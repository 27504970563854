#composite-video-container {
  position:relative;
  width:100%;
  height:0%;
  padding: 57% 0 0 0;
}
#publisher-canvas {
  position:absolute;
  background-color:gray;
  top:0;
  left:0;
  height:100%;
  width:100%;
}